<template>
  <div class="relevant">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>相关条款</span>
      </div>
      <el-button type="text" class="titleText" @click="dialogVisible = true">《共享经济合作伙伴协议》</el-button>
    </el-card>
    <el-dialog title="共享经济合作伙伴协议" :visible.sync="dialogVisible" :show-close="false">
      <div style="line-height:30px" v-html="content"></div>
      <div style="line-height:30px" v-html="qianzi(time,name)"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/personalapi";
import content from "../js/content";
import { qianzi } from "../js/xieyiqianzi";
export default {
  data() {
    return {
      dialogVisible: false,
      content,
      time:'',
      name:''
    };
  },
  created() {
    this._getSign();
  },
  methods: {
    qianzi,
    async _getSign() {
      const { status_code, message } = await api.getSignApi();
      if (status_code === 200) {
        this.time = ((message.time == null)?'--':message.time);
        this.name = message.name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.relevant /deep/ .el-button--text {
  color: #333333;
}
.relevant /deep/ .el-dialog__header {
  text-align: center;
}
.relevant /deep/ .el-dialog__body {
  height: 566px;
  overflow: auto;
}
.relevant /deep/.el-dialog {
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.titleText :hover {
  color: #ff6233;
  border-color: transparent;
  background-color: transparent;
}
</style>