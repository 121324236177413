<template>
  <div class="account">
    <el-row>
      <el-col :span="2">真实姓名：</el-col>
      <el-col :span="13">{{tempLegend.name}}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2">手机号：</el-col>
      <el-col :span="13">{{tempLegend.phone}}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2">邀请人：</el-col>
      <el-col :span="13">{{tempLegend.inviter_name}}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2">邀请人电话：</el-col>
      <el-col :span="13">{{tempLegend.inviter_phone}}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2">分成比：</el-col>
      <el-col :span="13">{{tempLegend.separate_ratio}}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2">安全设置：</el-col>
      <el-col :span="13">
        <el-button type="text"
                   class="change-pass"
                   @click="handlePwd">修改密码</el-button>
      </el-col>
    </el-row>
    <div class="cus_dialog">
      <el-dialog title="修改密码"
                 :visible.sync="dialogForm"
                 :show-close="false">
        <el-form :model="form"
                 ref="form"
                 label-position="right"
                 label-width="135px"
                 :rules="rules">
          <el-form-item label="新密码："
                        prop="password"
                        :error="errorMessage?errorMessage.password:''">
            <el-input v-model="form.password"
                      show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码："
                        prop="password_confirmation"
                        :error="errorMessage?errorMessage.password_confirmation:''">
            <el-input v-model="form.password_confirmation"
                      show-password></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
          <el-button @click="handleCancel">取消</el-button>
          <el-button class="form_btn"
                     type="primary"
                     @click="handleStore">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/api/personalapi";
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码"));
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(new Error("密码长度为6 - 18个字符"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: true,
      tempLegend: {
        name: "",
        phone: "",
        inviter_name: "",
        inviter_phone: "",
        separate_ratio: "",
      },
      errorMessage: null,
      form: {
        password: "",
        password_confirmation: "",
      },
      dialogForm: false,
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        password_confirmation: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  created () {
    this._getAccount()
  },
  mounted () { },
  methods: {
    //获取账户设置数据
    async _getAccount () {
      const data = await api.getAccountApi();
      if (data.status_code === 200) {
        this.tempLegend = data.message;
        this.loading = false
      }
    },
    handlePwd () {
      this.dialogForm = true;
    },
    handleStore () {
      this.errorMessage = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this._changePwd();
        } else {
          return false;
        }
      });
    },
    handleCancel () {
      this.dialogForm = false;
      this.form = {
        password: "",
        password_confirmation: "",
      };
      this.remove();
    },

    //修改密码接口
    async _changePwd () {
      const { status_code, message } = await api.changePwdApi(this.form);
      if (status_code === 200) {
        this.dialogForm = false;
        this.$message({
          message: message,
          type: "success",
        });
        this.remove();
      } else if (status_code === 422) {
        this.errorMessage = message;
      }
    },
    remove () {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.$refs.form.clearValidate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account /deep/ .el-col-2 {
  width: 84px;
}
.account /deep/.el-col-13 {
  padding: 0 15px;
}
.cus_dialog /deep/ .el-dialog__header {
  text-align: center;
  height: 58px;
}
.cus_dialog /deep/ .el-dialog__body {
  padding: 10px 35px 10px 0px;
}
.cus_dialog /deep/.el-dialog__footer {
  height: 58px;
  padding: 0px 20px 0px 0px;
  line-height: 58px;
}
.cus_dialog /deep/ .el-form-item {
  height: 58px;
  margin-bottom: 0px;
}
.cus_dialog /deep/ .el-form-item__content {
  line-height: 58px;
}
.cus_dialog /deep/ .el-form-item__label {
  line-height: 58px;
}
.cus_dialog /deep/ .el-input__inner {
  height: 34px;
  line-height: 58px;
}
.cus_dialog /deep/.el-button--primary {
  width: 85px;
}
.cus_dialog /deep/.el-button {
  width: 85px;
  height: 32px;
}
.cus_dialog /deep/.el-dialog {
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 262px;
}
.change-pass {
  font-weight: normal;
}
</style>