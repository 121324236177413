<template>
  <div class="personalpage" >
    <div class="headBox">个人中心</div>
    <el-card class="box-card" :body-style="{padding: '0 20px 0px 20px'}">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账户设置" name="account">
          <account></account>
        </el-tab-pane>
        <el-tab-pane label="实名认证" name="realname">
          <realname></realname>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <div class="cus_padding">
      <relevant></relevant>
    </div>
  </div>
</template>

<script>
import account from "./components/account";
import relevant from "./components/relevant";
import realname from "./components/realname";
export default {
  name: "personalpage",
  components: {
    account,
    relevant,
    realname,
  },
  data() {
    return {
      activeName: "account",
    };
  },
  // watch: {
  //   activeName: {
  //     handler: function (val) {
  //       if (val == 'account') {
  //         this._getAccount();
  //       } else if (val == 'realname') {
  //         this._getReal()
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  methods: {
    handleClick() {
      // this.loading = true
    },
  },
};
</script>

<style lang="less" scoped>
.headBox {
  height: 58px;
  line-height: 58px;
  color: #333333;
  font-size: 20px;
  font-weight: 500;
}
.personalpage /deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
.personalpage /deep/ .el-tabs__header {
  margin: 0 0 10px;
}
.personalpage /deep/ .el-tabs__item.is-top.is-active {
  color: #333333;
}
.personalpage /deep/ .el-tabs__item:hover {
  color: #333333;
}
.personalpage /deep/ .el-tabs__active-bar.is-top {
  width: 22px !important;
  // transform: translate(-50%)!important;
}
.personalpage /deep/ .el-tabs__active-bar {
  left: 16px;
  // left: 50%;
  // transform: translate(-50%,-50%)!important;
}
.personalpage /deep/ .el-tabs__nav-scroll {
  height: 58px;
  line-height: 58px;
}
.personalpage /deep/ .el-tabs__item.is-top {
  color: #999999;
}
.personalpage /deep/ .el-row {
  height: 58px;
  line-height: 58px;
  font-size: 14px;
  color: #666666;
}
.cus_padding {
  margin-top: 20px;
}
</style>
